import React, { memo, useCallback, useMemo, useState } from 'react';
import { Checkbox, styled } from '@mui/material';

import SearchBar from 'ui/SearchBar/SearchBar';
import SelectAllCheckbox from './SelectAllCheckbox';
import { Tree } from './Tree';
import {
    getAllNodeIds,
    getAllSelectableNodeAndTheirDescendantsIdsWithouRoot,
    getDisplayedTree,
    getNormalizedAndCaseInsensitiveRegex,
    matchIgnoringCaseAndAccents,
} from './helpers';
import { SelectableList } from './SelectableList';
import { cloneDeep } from 'lodash';

const StyContainer = styled('div', {
    name: 'StyContainer',
})(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 400,
}));

const StyHeader = styled('div', {
    name: 'StyHeader',
})(({ theme }) => ({
    '& > *': {
        marginBottom: theme.spacing(2),
    },
}));

const StyTreeContainer = styled('div', {
    name: 'StyTreeContainer',
})(() => ({
    flex: 1,
    overflow: 'auto',
    display: 'flex',
}));

type TreeWithOptionalIsDisabled = Expand<
    Omit<TreeT, 'children'> & {
        isDisabled?: boolean;
        children: TreeWithOptionalIsDisabled[];
    }
>;

const Node = memo(
    ({
        isChecked,
        node,
        handleNodeSelect,
    }: {
        isChecked: boolean;
        node: TreeWithOptionalIsDisabled;
        handleNodeSelect: (event: React.MouseEvent, node: TreeT) => void;
    }) => (
        <>
            <Checkbox
                checked={isChecked}
                onClick={(event) => handleNodeSelect(event, node)}
                disabled={node.isDisabled}
            />
            {node.label}
        </>
    ),
);

const getWrappedHandleNodeSelect =
    (handleNodeSelect: (node: TreeT) => void) =>
    (event: React.MouseEvent, node: TreeT) => {
        event.stopPropagation();
        handleNodeSelect(node);
    };

type Props = {
    tree: TreeWithOptionalIsDisabled;
    selectedNodeIds: number[];
    setSelectedNodeIds: React.Dispatch<React.SetStateAction<number[]>>;
    defaultExpandedIds?: string[];
    handleNodeSelect?: (node: TreeT) => void;
    withSearch?: boolean;
    withSelectAll?: boolean;
    isFlatTree?: boolean;
};

const SelectableTree = ({
    tree,
    selectedNodeIds,
    setSelectedNodeIds,
    defaultExpandedIds,
    handleNodeSelect: handleNodeSelectProps,
    withSearch = false,
    withSelectAll = false,
    isFlatTree = false,
}: Props) => {
    const hasHeader = withSearch || withSearch;
    const [searchedText, setSearchedText] = useState<string>('');

    const treeClone = cloneDeep(tree);
    // we're filtering the children to avoid label duplications
    // we can't ignore them sooner becacause we might need the keys
    // for the scopes
    if (isFlatTree) {
        treeClone.children = treeClone.children.filter(
            (child, index, self) =>
                index === self.findIndex((t) => t.label === child.label),
        );
    }

    const handleInputChange = useCallback((newValue: string) => {
        setSearchedText(newValue);
    }, []);

    const normalizedSearchedRegex = useMemo(
        () => getNormalizedAndCaseInsensitiveRegex(searchedText),
        [searchedText],
    );

    const defaultHandleNodeSelect = useCallback(
        (node: TreeT) => {
            setSelectedNodeIds((prevSelectedNodeIds) => {
                if (prevSelectedNodeIds.includes(node.id)) {
                    return prevSelectedNodeIds.filter((id) => id !== node.id);
                }
                return [...prevSelectedNodeIds, node.id];
            });
        },
        [setSelectedNodeIds],
    );

    const handleNodeSelect = useMemo(
        () =>
            getWrappedHandleNodeSelect(
                handleNodeSelectProps || defaultHandleNodeSelect,
            ),
        [defaultHandleNodeSelect, handleNodeSelectProps],
    );

    const rowRenderer = useCallback(
        (node: TreeWithOptionalIsDisabled) => {
            const isChecked = selectedNodeIds.includes(node.id);
            return (
                <Node
                    isChecked={isChecked}
                    node={node}
                    handleNodeSelect={handleNodeSelect}
                />
            );
        },
        [handleNodeSelect, selectedNodeIds],
    );

    const mustNodeBeDisplayed = useCallback(
        (nodeLabel: string): boolean => {
            if (searchedText === '') return true;

            return matchIgnoringCaseAndAccents(
                nodeLabel,
                normalizedSearchedRegex,
            );
        },
        [searchedText, normalizedSearchedRegex],
    );

    const displayedTree = useMemo(
        () => getDisplayedTree(treeClone, mustNodeBeDisplayed),
        [treeClone, mustNodeBeDisplayed],
    );

    const allDisplayedStringNodeIds = useMemo(
        () => getAllNodeIds(displayedTree).map((id) => String(id)),
        [displayedTree],
    );

    const allSelectableNodeIds = useMemo(
        () =>
            getAllSelectableNodeAndTheirDescendantsIdsWithouRoot(
                treeClone,
                mustNodeBeDisplayed,
            ),
        [mustNodeBeDisplayed, treeClone],
    );

    const flatTreeValues = useMemo(
        () =>
            isFlatTree
                ? treeClone.children.filter((node) =>
                      mustNodeBeDisplayed(node.label),
                  )
                : [],
        [isFlatTree, mustNodeBeDisplayed, treeClone.children],
    );

    const selector = isFlatTree ? (
        <SelectableList values={flatTreeValues} rowRenderer={rowRenderer} />
    ) : (
        <Tree
            tree={displayedTree}
            rowRenderer={rowRenderer}
            expandedIds={searchedText ? allDisplayedStringNodeIds : undefined}
            defaultExpandedIds={defaultExpandedIds}
        />
    );

    return (
        <StyContainer>
            {hasHeader && (
                <StyHeader>
                    {withSearch && (
                        <SearchBar
                            handleChange={handleInputChange}
                            width="100%"
                            sx={{ maxWidth: 450 }}
                        />
                    )}
                    {withSelectAll && (
                        <SelectAllCheckbox
                            selectedNodeIds={selectedNodeIds}
                            setSelectedNodeIds={setSelectedNodeIds}
                            allSelectableNodeIds={allSelectableNodeIds}
                        />
                    )}
                </StyHeader>
            )}
            <StyTreeContainer>{selector}</StyTreeContainer>
        </StyContainer>
    );
};

export default SelectableTree;
